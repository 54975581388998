import axios from 'axios';
import authHeader from './auth-header';
import Product from "../models/Product"

const API_URL = `${process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://localhost:5001'}/api/`;

class ProductService {
  getProducts(){
    return axios.get<Product[]>(API_URL + `products`, { headers: authHeader() });
  }
}

export default new ProductService();
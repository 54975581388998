import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2b182cc5")
const _hoisted_1 = { class: "relative bg-white shadow-lg rounded-t-2xl sm:rounded-2xl p-4 sm:p-12 pt-10 h-screen sm:h-auto w-screen sm:w-auto mt-20 sm:mt-0" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-gray-400 bg-opacity-30 overflow-y-auto h-full w-full z-20"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showing)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "fixed inset-0 h-screen sm:h-auto overflow-hidden my-auto flex items-center justify-center bg-semi-75 z-40 shadow-lg sm:bg-opacity-0 bg-gray-400 bg-opacity-50",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"]))
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("button", {
                  "aria-label": "close",
                  class: "absolute top-0 right-0 text-2xl text-gray-500 my-2 mx-4",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["prevent"]))
                }, " x "),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (_ctx.showing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}
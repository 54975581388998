
import { defineComponent } from "vue";
import SideNav from "@/components/template/SideNav.vue";
import TopBar from "@/components/template/TopBar.vue"
import Footer from "@/components/template/Footer.vue";
import AccountCard from "@/components/AccountCard.vue";

export default defineComponent({
  name: "Account",
  data() {
    return {
      firstname: "",
      menu: true,
      route: "/account"
    }
  },
  components: {
    AccountCard,
    SideNav,
    TopBar,
    Footer
  },
  mounted() {
    this.firstname = this.$store.state.auth.user.firstName;
  },
  methods: {
    menuOpen() {
      this.menu = !this.menu
    }
  }
});

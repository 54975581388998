
  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "GButton",
    data() {
      return {
      };
    },
    props: {
      text: {
        required: true,
        type: String,
      },
    },
  });
  
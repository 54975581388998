
import { defineComponent } from "vue";
import DashboardCards from "@/components/DashboardCards.vue";
import SideNav from "@/components/template/SideNav.vue";
import TopBar from "@/components/template/TopBar.vue"
import Footer from "@/components/template/Footer.vue";
import EnvelopeModal from "@/components/docusign/EnvelopeModal.vue";
import { useRoute } from 'vue-router';
import AuthService from "../services/auth.service";

export default defineComponent({
  name: "Home",
  data() {
    return {
      firstname: "",
      menu: true,
      route: "/",
      verify: "",
      verifiedModal: false,
      errorModal: false
    };
  },
  components: {
    DashboardCards,
    SideNav,
    TopBar,
    Footer,
    EnvelopeModal
  },
  async mounted() {
    this.firstname = this.$store.state.auth.user.firstName;
    const route = useRoute();
    const query = route.query;
    this.verify = (query.verify === undefined || query.verify === null) ? "" : query.verify.toString();

    if(this.verify.length > 0) {
      try {
        await AuthService.checkemail(this.verify);
        this.verifiedModal = true;
      } catch(e) {
        this.errorModal = true;
      }
    }
  },
  methods: {
    menuOpen() {
      this.menu = !this.menu;
    },
  },
});

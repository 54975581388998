
import { defineComponent } from "vue";
import GrabbaSigner from "@/models/docusign/GrabbaSigner";
import DocusignService from "@/services/docusign.service";
import EnvelopeDocument from "@/components/docusign/EnvelopeDocument.vue";

export default defineComponent({
  name: "VerificationDetail",
  data() {
    return {
     tmpSigner: this.signer
    };
  },
  props: {
    signer: Object as () => GrabbaSigner,
  },
  methods: {
    async getVerificationImage(signerGuid: string): Promise<string> {
      try {
        let res = await DocusignService.getVerificationImageBySignerGuid(signerGuid ?? '');
        return res.data;
      } catch (error) {
        // probably some kind of authorization error
        console.log("We got some kind of error: " + error);
        return "";
      }
    },
  },
  async mounted() {
    if(this.tmpSigner && this.tmpSigner.envelope_complete) {
        this.tmpSigner.verification_image = await this.getVerificationImage(this.tmpSigner.grabba_signer_id!)
    } 
  }
});

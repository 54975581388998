
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      sidebarToggle: false
    };
  },
  props: {
    menuopen: Boolean,
    active: String
  },
  methods: {
    toggleSidebar() {
      this.sidebarToggle = !this.sidebarToggle;
    },
    clickLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  watch: {
    menuopen: function () {
      this.toggleSidebar();
    }
  }
});


  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "GModal",
    data() {
      return {
      };
    },
    props: {
      showing: {
        required: true,
        type: Boolean,
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
  });
  

import { defineComponent } from "vue";
import GrabbaEnvelope from "@/models/docusign/GrabbaEnvelope";
import GrabbaSigner from "@/models/docusign/GrabbaSigner";
import DocusignService from "@/services/docusign.service";
import EnvelopeDocument from "@/components/docusign/EnvelopeDocument.vue";
import VerificationDetail from "./VerificationDetail.vue";
import DocusignNotification from "@/components/docusign/DocusignNotification.vue";
import EnvelopeModal from "./EnvelopeModal.vue";
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import { reactive } from 'vue';
import { throwStatement } from "@babel/types";

export default defineComponent({
  name: "EnvelopeDetail",
  setup() {
    const state = reactive({
      delete: ''
    });

    const textCorrect = (text: any): boolean => {
      return (text === "DELETE");
    };

    const rules = {
      delete: {
        required: helpers.withMessage("Please enter a value.", required),
        textCorrect: helpers.withMessage("Text must equal 'DELETE' in uppercase.", textCorrect)
     },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ }
  },
  data() {
    return {
      docusignLoading: false,
      isSigner: undefined as GrabbaSigner | undefined,
      tmpSigners: this.envelope?.recipients,
      isAuditor: false,
      auditNotificationShowing: false,
      envIncompleteNotification: false,
      confirmDeleteDialog: false,
      deleteLoading: false,
      isSender: false
    };
  },
  props: {
    envelope: Object as () => GrabbaEnvelope,
  },
  components: {
    EnvelopeDocument,
    VerificationDetail,
    DocusignNotification,
    EnvelopeModal
  },
  computed: {
    lastModified() {
      const d = new Date();
      if (this.envelope) {
        // timezoneoffset returns a value in minutes
        return (
          new Date(this.envelope.last_modified).getTime() -
          d.getTimezoneOffset() * 60 * 1000
        );
      }
      return d.getTime();
    },
  },
  methods: {
    statusClass(status: boolean) {
      return {
        "bg-green-500": status === true,
        "bg-yellow-500": status === false,
      };
    },
    docusignSignLink() {
      this.docusignLoading = true;
      if (this.isSigner) {
        DocusignService.getSigningUrl(this.isSigner.grabba_signer_id ?? "")
          .then((value) => {
            // some weird docusign behaviour that we're getting spat out right away
            window.location.replace(value.data);
          })
          .catch((e) => {
            console.log("Error Occured ");
            console.log(e);
            this.docusignLoading = false;
          });
      }
    },
    docusignAuditButton() {
      this.isAuditor = true;
      if (
        this.envelope !== undefined &&
        this.envelope.sender.grabba_sender_id !== undefined
      ) {
        for (var i = 0; i < this.envelope.recipients.length; i++) {
          if (!this.envelope.recipients[i].envelope_complete) {
            this.envIncompleteNotification = true;
            return;
          }
        }
        DocusignService.sendAuditRequest(
          this.envelope.sender.grabba_sender_id.toString()
        )
          .then((value) => {
            this.auditNotificationShowing = true;
          })
          .catch((error) => {
            console.log("Some error requesting audit access");
            console.log(error);
          });
      }
    },
    clickDelete() {
      this.confirmDeleteDialog = true;
    },
    async docusignDeleteButton() {
      const isFormCorrect = await this.v$.$validate();
      if(this.envelope && isFormCorrect) {
        this.deleteLoading = true;
        DocusignService.deleteEnvelope(this.envelope?.envelope_id)
          .then((value) => {
            console.log("Envelope deleted");
            this.deleteLoading = false;
            window.location.replace('/docusign');
          })
          .catch((error) => {
            console.log(error);
            this.deleteLoading = false;
          });
      }
    },
  },
  async mounted() {
    var email = this.$store.state.auth.user.email;
    var filteredRecipients = this.envelope?.recipients.filter((a) => {
      return a.signer_email === email && !a.envelope_complete;
    });
    if (filteredRecipients && filteredRecipients.length === 1) {
      this.isSigner = filteredRecipients[0];
    }
    if (this.$store.state.auth.user.docusignEmail === this.envelope?.sender.email) {
      this.isSender = true;
    }
    var filteredAuditors = this.envelope?.auditors.filter((a) => {
      return a.auditor_email === email;
    });
    if (
      (filteredAuditors && filteredAuditors.length === 1) ||
      this.envelope?.sender.email == email
    ) {
      this.isAuditor = true;
    }
  },
});

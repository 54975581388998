
import { defineComponent } from "vue";
import Product from "@/models/Product";
import Article from "@/models/Article";
import ProductService from "@/services/product.service";
import ArticleService from "@/services/article.service";
let products: Product[] | undefined = undefined;
let searchArticles: Article[] | undefined = undefined;
let error: any;

export default defineComponent({
  data() {
    return {
      products,
      error,
      searchArticles,
      searchTerm: "",
    };
  },
  async mounted() {
    // Get article from service
    try {
      let accRes = await ProductService.getProducts();
      if (accRes.data) {
        this.products = accRes.data;
      }
    } catch (e) {
      console.log(e);
      this.error = e;
    }
  },
  methods: {
    async searcher() {
      if (this.searchTerm && this.searchTerm.length > 0) {
        try {
          let accRes = await ArticleService.searchArticle(encodeURIComponent(this.searchTerm));
          if (accRes.data) {
            if(accRes.data.length > 0) {
              this.searchArticles = accRes.data;
            } else {
              this.searchArticles = undefined;
            }
          }
        } catch (e) {
          console.log(e);
          this.error = e;
          this.searchArticles = undefined;
        }
      }
    },
    clearQuery() {
      this.searchTerm = "";
      this.searchArticles = undefined;
    }
  },
});

import axios from 'axios';
import authHeader from './auth-header';
import User from "../models/User"

const API_URL = `${process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://localhost:5001'}/api/`;

class AccountService {
  getAccountDetails(userId: string){
    return axios.get<User>(API_URL + `account`, { headers: authHeader() });
  }

  deleteUserAccount() {
    return axios.get(API_URL + `delete_account`, {headers: authHeader()});
  }
  
}

export default new AccountService();
import AuthService from '../services/auth.service';
import User from '../models/User'

const user = JSON.parse(localStorage.getItem('user')!);
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

interface Status {
    loggedIn:boolean
}

interface State {
    status:Status,
    user: User | null
}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }:{commit:any}, data:string[]) {
      return AuthService.login(data[0], data[1], data[2]).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }:{commit:any}) {
      AuthService.logout();
      commit('logout');
    },
    setDocusignEmail({ commit }:{commit:any}, email:string) {
      commit('setDocusignEmail', email);
    },
  },
  mutations: {
    loginSuccess(state:State, user:User) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state:State) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state:State) {
      state.status.loggedIn = false;
      state.user = null;
    },
    setDocusignEmail(state:State, email:string) {
      if(state.user)
          state.user.docusignEmail = email;
    }
  }
};

import { defineComponent } from "vue";
import { useRoute } from 'vue-router';
import TopBar from "@/components/template/TopBar.vue"
import SideNav from "@/components/template/SideNav.vue";
import Footer from "@/components/template/Footer.vue";
import DocusignEnvelopeList from "@/components/docusign/EnvelopeList.vue";
import LinkAccountCard from "@/components/docusign/LinkAccountCard.vue";
import LoadingCard from "@/components/docusign/LoadingCard.vue";
import DocusignService from "@/services/docusign.service";
import EnvelopeModal from "@/components/docusign/EnvelopeModal.vue";
import EnvelopeDetail from "@/components/docusign/EnvelopeDetail.vue"
import GrabbaEnvelope from "@/models/docusign/GrabbaEnvelope"

export default defineComponent({
  name: "Docusign",
  data() {
    return {
      firstname: "",
      menu: true,
      route: "/docusign",
      state: "",
      accessGranted: false, 
      envelopeId: "",
      authCode: "", 
      event: "", 
      linkAccountMessage: "",
      signerId: "",
      signCompleteModalShow: false, 
      auditRequestModalShow: false,
      auditRequestFailedModalShow: false, 
      auditGrantedModalShow: false,
      auditRejectedModalShow: false,
      auditAccessFailedModalShow: false,
      detailModalShowing: false,
      accessLoading: true,
      auditEnvelope: undefined as GrabbaEnvelope | undefined, 
      errorText: "", 
      errorModalShow: false
    };
  },
  components: {
    SideNav,
    Footer,
    TopBar,
    DocusignEnvelopeList,
    LinkAccountCard, 
    LoadingCard,
    EnvelopeModal, 
    EnvelopeDetail
  },
  mounted() {
    this.firstname = this.$store.state.auth.user.firstName;
    // TODO - Check route and set intent for the envelope list page based on that
    // stage-portal.grabba.com/docusign?state=sign&id={{guid}}
    // Grab state variable from the route 
    const route = useRoute();
    const query = route.query;
    this.state = (query.state === undefined || query.state === null) ? "" : query.state.toString();
    /*
     * Params we receive on a redirect:
     *  always:
     *    - state: the state variable we pass in
     *  access_granted:
     *    - code: the authorization code we get from the docusign lads
     * sign:
     *    - id: the unique id for the user?
     *    - envelope id: the id for the user to try and sign
     * sign_complete: 
     *    - event: this will be "signing_complete", "cancel", or "decline". However, docusign recommends we don't make business decisions on it as it can be spoofed.
     *             we should query the docusign api to be sure. 
     * send: 
     *    If we get here, we should just pop up the sending modal ui
     * send_complete:
     *    - event: similar to sign complete
     *    - envelopeId: the id for the created envelope - we'll need to use this to get all the urls. 
     * audit:
     *    - senderId: the id of the sender to request access from
     * audit_granted:
     *    - senderId: the sender granting the access
     *    - auditorId: the auditor getting the access
     *    - accessGranted: whether access has been granted or not
     */

    if (this.state !== "access" && this.state !== "sign" && this.state !== "sign_inprogress") {
      DocusignService.checkAccessToken().then((value) => {
        localStorage.setItem('redirectIntent', window.location.href);
        this.accessLoading = false;
        this.accessGranted = value.data.access;
        console.log("Setting docusign Email " + value.data.docusignEmail);
        this.$store.dispatch("auth/setDocusignEmail", value.data.docusignEmail);
        console.log(this.$store.state.auth.user.docusignEmail);
      }).catch((error) => {
          console.log(error);
      })
    }
    switch(this.state) {
      case "sign":
        // the code for this is handled in the LoadingCard.vue - leaving this here for clarity
        this.accessGranted = true;
        if (query.signerid !== null) {
          this.signerId = query.signerid.toString();
        }
        break;
      case "sign_inprogress":
        // TODO - Hit the updateNotify
        this.accessGranted = true;
        if (query.signerid !== null) {
          DocusignService.updateEnvelope(query.signerid?.toString()).then((value) => {
            window.location.href = `https://${process.env.VUE_APP_GO_RETURN_DOMAIN}/docusign?state=sign_complete`;
          }).catch((error) => {
              console.log(error);
          });
        }
        break;
      case "sign_complete":
        this.signCompleteModalShow = true;
        break;
      case "send":
        // we want to pop-up the modal view here
        break;
      case "send_complete":
        if (query.event !== null && query.envelopeId !== null) {
          switch (query.event.toString()) {
            case "Send":
              this.envelopeId = query.envelopeId.toString();
              break;
            case "Cancel":
            case "Save":
              break;
          }
        }
        // this show continues in the LoadingCard.
        break;
      case "access":
        if (query.code !== null) {
          this.authCode = query.code.toString();
          console.log(this.authCode);
          this.accessGranted = true;
        }
        // also in the loading card
        break;
      case "audit":
        // now we're doing something here to request audit access
        if (query.senderId !== null)
        {
          DocusignService.getDetailedEnvelopeBySenderId(query.senderId.toString()).then((value) => {
            if (value.data !== null && value.data !== undefined) {
              this.detailModalShowing = true;
              this.auditEnvelope = value.data;
            }
          }).catch((error) => {
            if (query.senderId !== null) {
              
              DocusignService.sendAuditRequest(query.senderId.toString()).then((value) => {
                this.auditRequestModalShow = true;
              }).catch((error) => {
                this.auditRequestFailedModalShow = true;
              });
            } else {
              console.log("Error: Requesting audit access failed.");
            }
          });
        }
        else
        {
          console.log("Error: Requesting audit access failed.");
        }
        break;
      case "audit_granted":
        if (query.senderId !== null && query.auditorId !== null && query.access_granted !== null)
        {
          DocusignService.grantAuditAccess(query.senderId.toString() , query.auditorId.toString(), (query.access_granted === 'True' || query.access_granted === 'true')).then((response) => {
            if (response.data === false) {
                this.auditAccessFailedModalShow = true;
            } else if (query.access_granted === 'True' || query.access_granted === 'true') {
              this.auditGrantedModalShow = true;
            } else {
              this.auditRejectedModalShow = true;
            }
          }).catch((error) => {
            this.auditAccessFailedModalShow = true;
          });
          
        }
        else
        {
          console.log("Error: Malformed audit_granted query.");
        }
        break;
      default:
      // we shouldn't need to specify anything additional
        break;
    }
  },
  methods: {
    menuOpen() {
      this.menu = !this.menu;
    }, 
    errorOccurred(error: any) {
      this.errorModalShow = true;
      this.errorText = error.toString();
      this.state = "";
      this.signerId = "";
      this.envelopeId = "";
      this.authCode = "";
      this.event = "";
    }, 
    hideErrorModal() {
      this.errorModalShow = false;
      this.errorText = ""; 
      window.location.replace('/docusign');
    }, 
    hideSignCompleteModal() {
      this.signCompleteModalShow = false;
      window.location.replace('/docusign');
    }, 
    hideAuditRequestModal() {
      this.auditRequestModalShow = false;
      window.location.replace('/docusign');
    },
    hideAuditGrantedModal() {
      this.auditGrantedModalShow = false;
      window.location.replace('/docusign');
    }, 
    hideAuditRequestFailedModal() {
      this.auditRequestFailedModalShow = false;
      window.location.replace('/docusign');
    }, 
    hideAuditRejectedModal() {
      this.auditRejectedModalShow = false;
      window.location.replace('/docusign');
    }, 
    hideAuditAccessFailedModal() {
      this.auditAccessFailedModalShow = false;
      window.location.replace('/docusign');
    },
  }
});

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import jwt_decode from 'jwt-decode';
import store from '@/store';

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Documentation from '../views/Documentation.vue'
import Doc from '../views/Doc.vue'
import Downloads from '../views/Downloads.vue'
import Account from '../views/Account.vue'
import Redirect from '../views/Redirect.vue'
import Licenses from '../views/Licenses.vue'
import Docusign from '../views/docusign/Docusign.vue'
import PageNotFound from '../views/PageNotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/r/:redirect',
    name: 'Redirect',
    component: Redirect,
    meta: {
      title: 'Redirect'
    }
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation,
    meta: {
      title: 'Documentation'
    }
  },
  {
    path: '/docusign',
    name: 'Docusign',
    component: Docusign,
    meta: {
      title: 'Docusign'
    }
  },
  {
    path: '/doc/:article',
    name: 'Document View',
    component: Doc,
    meta: {
      title: 'Documentation'
    }
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads,
    meta: {
      title: 'Downloads'
    }
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: Licenses,
    meta: {
      title: 'Licenses'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Account'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: PageNotFound,
    meta: {
      title: '404 - Not Found'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Setting page title based on the field in the route definition
  if(typeof(to.meta.title) === 'string'){
    document.title = to.meta.title + ' | Grabba Portal'
  }

  if (to.name === "not-found") {
    next();
    return;
  }

  // Defining public pages without a login
  let publicPages = ['/login'];
  if(to.path[1] == 'r') {
    publicPages.push(to.path);
  }

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  publicPages = ['/login'];

  if (authRequired && !loggedIn) {
    if (to.fullPath.length > 0 && to.fullPath !== '/') {
      next(`/login?return=${encodeURIComponent(to.fullPath)}`);
      return;
    } else {
      next(`/login`);
      return;
    }
  } else if (authRequired && loggedIn)
  {
    const parsedUser = JSON.parse(loggedIn);
    const jwtPayload: any = jwt_decode(parsedUser.token);
    if (jwtPayload.exp < Date.now()/1000) {
      // Token expired
      store.dispatch('auth/logout');
      next("/login");
      return;
    }
    next();
  } else if (loggedIn) {
    next('/');
    return;
  } else {
    next();
    return;
  }
});

export default router

import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/cross.svg'
import _imports_1 from '@/assets/search.svg'


const _hoisted_1 = { class: "relative my-1" }
const _hoisted_2 = {
  key: 0,
  class: "block w-7 h-7 text-center text-xl leading-0 absolute top-2 right-2 text-gray-400 focus:outline-none hover:text-gray-900 transition-colors"
}
const _hoisted_3 = {
  key: 1,
  class: "block w-7 h-7 text-center text-xl leading-0 absolute top-2 right-2 text-gray-400 focus:outline-none hover:text-gray-900 transition-colors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchTerm = $event)),
      autocomplete: "off",
      type: "text",
      id: "password",
      class: "w-full sm:w-96 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-grabba-blue transition-colors",
      placeholder: "Search envelopes..."
    }, null, 512), [
      [_vModelText, _ctx.searchTerm]
    ]),
    (!(_ctx.search === undefined || _ctx.search.length === 0 || !(_ctx.searchTerm.length === 0 || _ctx.searchTerm === _ctx.search)))
      ? (_openBlock(), _createElementBlock("button", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "w-4 h-4 inline",
            alt: "",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelSearch && _ctx.cancelSearch(...args)))
          })
        ]))
      : (_openBlock(), _createElementBlock("button", _hoisted_3, [
          _createElementVNode("img", {
            src: _imports_1,
            class: _normalizeClass(["w-5 h-5 inline", { hidden: !(_ctx.searchTerm.length > 0) || _ctx.searchTerm === _ctx.search}]),
            alt: "",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitSearch && _ctx.emitSearch(...args)))
          }, null, 2)
        ]))
  ]))
}
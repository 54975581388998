
import docusignService from "@/services/docusign.service";
import { defineComponent } from "vue";
import User from "../models/User";
import AccountService from "../services/account.service";
import AuthService from "../services/auth.service";
import GButton from "./template/GButton.vue";
import GModal from "./template/GModal.vue";


let user:User = {
    firstName: "Loading",
    lastName: "User",
    email: "",
    token: "",
    createdOn: "",
    emailConfirmed: false
};

export default defineComponent({
    data() {
        return {
            user,
            confirmModal: false
        };
    },
    async mounted() {
        let accRes = await AccountService.getAccountDetails(this.$store.state.auth.user.userName);
        if (accRes.data) {
            this.user = accRes.data;
        }
    },
    methods: {
        clickLogout() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        async clickUnlinkModal() {
          this.confirmModal = true;
        },
        async clickUnlink() {
            let verifyRes = await docusignService.unlink();
            if (verifyRes.status == 200) {
                console.log("All good!");
            }
            this.confirmModal = false;
        },
        async clickSendVerifyEmail() {
            let verifyRes = await AuthService.verifyemail();
            if (verifyRes.status == 200) {
                console.log("All good!");
            }
        },
        async clickDelete() {
            let deleteResult = await AccountService.deleteUserAccount();
            if (deleteResult.status === 200) {
                console.log("Deleted successfully!");
            }
        }
    },
    components: { GModal, GButton }
});


import { defineComponent, ref } from "vue";
import QRCodeStyling from "qr-code-styling";
import { v4 as uuidv4 } from "uuid";
import DocusignService from "@/services/docusign.service";
import EnvelopeRequest from "@/models/docusign/EnvelopeRequest";
import GrabbaSigner from "@/models/docusign/GrabbaSigner";
import GrabbaDocument from "@/models/docusign/GrabbaDocument";
import GrabbaEnvelope from "@/models/docusign/GrabbaEnvelope";
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import { reactive } from 'vue';

import { PDFDocument, PDFString, PDFName, PDFArray } from "pdf-lib";
import GrabbaSender from "@/models/docusign/GrabbaSender";

export default defineComponent({
  name: "EnvelopeCreate",
  setup() {
    const state = reactive({
      subject: '',
      files: [] as File[],
      emails: [] as string[],
      names: [] as string[]
    });

    const hasNoDuplicateEmail = (array: any): boolean => {
      return (new Set(array)).size === array.length;
    };

    const rules = {
      subject: { required: helpers.withMessage("A subject is required", required) }, // Matches state.firstName
      files: { required: helpers.withMessage("At least one file must be added", required) }, // Matches state.lastName
      emails: {
        required: helpers.withMessage("At least one recipient must be added", required),
        hasNoDuplicateEmail: helpers.withMessage("Cannot have duplicate recipients", hasNoDuplicateEmail)
     },
      names: { required: helpers.withMessage("A name must be added", required) }
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ }
  },
  data() {
    return {
      recipients: [] as GrabbaSigner[],
      docusignLoading: false,
      emailError: "",
      emails: [] as string[],
      names: [] as string[],
      recipientEmailInput: "",
      recipientNameInput: "",
      senderId: "",
      editEnv: false,
      shake: false
    };
  },
  components: {
  },
  props: {
    envelopeId: String,
  },
  computed: {
    sendDisabled() {
      if (this.state.files.length === 0 || this.recipients.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (
      this.envelopeId !== undefined &&
      this.envelopeId !== null &&
      this.envelopeId !== ""
    ) {
      this.editEnvelope(this.envelopeId);
    }

    (this.$refs.submit as any).addEventListener("animationend", () => {
      this.shake = false;
    });
  },
  methods: {
    async editEnvelope(envId: string | undefined) {
      this.recipients = [] as GrabbaSigner[];
      this.state.files = [] as File[];
      if (envId !== undefined && envId !== null && envId.length > 0) {
        this.editEnv = true;
        try {
          let response = await DocusignService.getDetailedEnvelope(envId);
          let env: GrabbaEnvelope = response.data;

          for (var i = 0; i < env.recipients.length; i++) {
            if (env.recipients.at(i) !== undefined) {
              let trueRecip: GrabbaSigner = env.recipients.at(
                i
              ) as GrabbaSigner;
              this.addRecipient(trueRecip.signer_email, trueRecip.signer_name);
            }
          }

          this.state.subject = env.envelope_subject;

          for (i = 0; i < env.documents.length; i++) {
            if (env.documents.at(i) !== undefined) {
              let realDoc: GrabbaDocument = env.documents.at(
                i
              ) as GrabbaDocument;
              if (
                realDoc.grabba_document_id !== null &&
                realDoc.grabba_document_id !== undefined
              ) {
                let res = await DocusignService.getDocument(
                  envId,
                  realDoc.document_id.toString()
                );
                var blob = new Blob([res.data]);
                var b: any = blob;
                b.lastModifiedDate = new Date();
                b.name = realDoc.document_name + realDoc.document_extension;
                this.state.files.push(blob as File);
              }
            }
          }
          let sender: GrabbaSender = env.sender;
          this.senderId = sender.grabba_sender_id;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onInvalidSubmit({ values, errors, results }: any) {
      console.log(values);
      console.log(errors);
      console.log(results);
    },
    async createEnvelope() {
      // Validate form before we begin
      const isFormCorrect = await this.v$.$validate();

      // Make request to the docusign service and get the list of documents!
      if (isFormCorrect) {
        for (let i = 0; i < this.state.emails.length; i++) {
          this.addRecipient(this.state.emails[i], this.state.names[i]);
        }

        this.docusignLoading = true;

        if (
          this.senderId === null ||
          this.senderId === undefined ||
          this.senderId === ""
        ) {
          this.senderId = uuidv4();
        }

        try {
          // Create the list of documents and parse each file into a base64
          let documents: GrabbaDocument[] = [];
          for (let i = 0; i < this.state.files.length; i++) {
            let file = this.state.files[i];
            let base64 = await this.getBase64(file);
            const pdfDoc = await PDFDocument.load(base64);
            const pages = pdfDoc.getPages();
            let styledqr = await new QRCodeStyling({
              width: 150,
              height: 150,
              data: `https://${process.env.VUE_APP_GO_RETURN_DOMAIN}/docusign?state=audit&senderId=${this.senderId}`,
              image: `/grabba_globe.svg`,
              dotsOptions: {
                color: "#1c175f",
              },
              backgroundOptions: {},
              imageOptions: {
                crossOrigin: "anonymous",
                margin: 0,
              },
              qrOptions: {
                errorCorrectionLevel: "M",
              },
            }).getRawData("png");

            if (styledqr !== null) {
              var arrBuf = await new Response(styledqr).arrayBuffer();
              const pngImage = await pdfDoc.embedPng(arrBuf);
              const pngDims = pngImage.scale(0.5);

              
              

              for (let x = 0; x < pages.length; x++) {
                // Link
                const linkAnnotation = pdfDoc.context.obj({
                  Type: 'Annot',
                  Subtype: 'Link',
                  Rect: [10, 10, pngDims.width, pngDims.height],
                  Border: [0, 0, 0],
                  C: [0, 0, 1],
                  A: {
                    Type: 'Action',
                    S: 'URI',
                    URI: PDFString.of(`https://${process.env.VUE_APP_GO_RETURN_DOMAIN}/docusign?state=audit&senderId=${this.senderId}`),
                  },
                });
                const linkAnnotationRef = pdfDoc.context.register(linkAnnotation);

                var page = pages[x];
                page.drawImage(pngImage, {
                  x: 10,
                  y: 10,
                  width: pngDims.width,
                  height: pngDims.height,
                });
                const annots = page.node.lookup(PDFName.of('Annots'), PDFArray);
                annots.push(linkAnnotationRef);
                page.node.set(PDFName.of('Annots'), annots);
              }
            }

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();
            let pdfB64 = this.bytesToBase64(pdfBytes);

            documents.push({
              base_64_doc: pdfB64,
              document_name: file.name,
              document_extension: ".pdf",
              document_id: i + 1,
              envelope_id: "EnvelopeID",
              grabba_document_id: uuidv4(),
            });
          }

          const tmpEnvelope: EnvelopeRequest = {
            envelope_subject: this.state.subject,
            documents: documents,
            signers: this.recipients,
          };
          if (
            this.editEnv === false ||
            this.envelopeId === undefined ||
            this.envelopeId === ""
          ) {
            let res = await DocusignService.getEmbeddedSendingSession(
              tmpEnvelope,
              `https://${process.env.VUE_APP_GO_RETURN_DOMAIN}/docusign?state=send_complete`,
              this.senderId
            );

            window.location.replace(res.data);
          } else {
            // we need to make sure that any newly added document ids are set correctly here
            let res = await DocusignService.updateEmbeddedSendingSession(
              this.envelopeId as string,
              tmpEnvelope,
              `https://${process.env.VUE_APP_GO_RETURN_DOMAIN}/docusign?state=send_complete`,
              this.senderId
            )
              .then((result) => {
                window.location.replace(result.data);
              })
              .catch((error) => {
                console.log(
                  "We got some kind of error back from the server :("
                );
                console.log(error);
              });
          }
          this.editEnv = false;
          // Redirect to the sending url
        } catch (e) {
          // TODO - Emit error event and send that through to the error handler
          console.log(e);
        }
      } else {
        // Shake!
        this.shake = true;
        console.log("Form was invalid!");
      }
    },
    isEmail(emailAddr: string) {
      var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
      return reg.test(emailAddr);
    },
    async dropFile(e: any) {
      let droppedFiles: FileList = e.dataTransfer.files;
      if (droppedFiles) {
        this.state.files.push(droppedFiles[0]);
      }
      await this.v$.files.$validate();
      (this.$refs.selectedFile as any).value = "";
      return;
    },
    async selectFile(e: any) {
      let droppedFiles: FileList = e.target.files;
      if (droppedFiles) {
        this.state.files.push(droppedFiles[0]);
      }
      await this.v$.files.$validate();
      (this.$refs.selectedFile as any).value = "";
      return;
    },
    async removeFile(file: File) {
      this.state.files = this.state.files.filter((f) => {
        return f != file;
      });
      await this.v$.files.$validate();
    },
    addRecipient(address: string, name: string) {
      this.recipients.push({
        signer_email: address,
        signer_name: name,
        client_id: this.recipients.length + 1,
        recipient_id: this.recipients.length + 1,
        routing_order: 1,
        redirect_url: "",
        state: "sign_complete",
        envelope_complete: false,
        grabba_signer_id: undefined!,
        signature_base_64: "",
        verification_image: "",
      });
    },
    removeEmail(recipientIndex: number) {
      this.state.emails.splice(recipientIndex, 1);
    },
    addEmailInput() {
      if (this.recipientEmailInput.length > 0 && this.isEmail(this.recipientEmailInput) && this.recipientNameInput.length > 0) {
        this.state.emails.push(this.recipientEmailInput);
        this.state.names.push(this.recipientNameInput);
        this.recipientEmailInput = "";
        this.recipientNameInput = "";
        this.emailError = "";
      } else {
        this.emailError = "Please enter a valid email and name";
      }
    },
    getBase64(file: File): Promise<string> {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result?.toString().split(",")[1] || "");
        };
        reader.onerror = (error) => reject(error);
      });
    },
    bytesToBase64(bytes: Uint8Array) {
      const base64abc = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "+",
        "/",
      ];
      let result = "",
        i,
        l = bytes.length;
      for (i = 2; i < l; i += 3) {
        result += base64abc[bytes[i - 2] >> 2];
        result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
        result += base64abc[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)];
        result += base64abc[bytes[i] & 0x3f];
      }
      if (i === l + 1) {
        // 1 octet yet to write
        result += base64abc[bytes[i - 2] >> 2];
        result += base64abc[(bytes[i - 2] & 0x03) << 4];
        result += "==";
      }
      if (i === l) {
        // 2 octets yet to write
        result += base64abc[bytes[i - 2] >> 2];
        result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
        result += base64abc[(bytes[i - 1] & 0x0f) << 2];
        result += "=";
      }
      return result;
    },
    selectedFileClick() {
      var selectedFile = this.$refs["selectedFile"] as HTMLElement;
      selectedFile.click();
    },
  },
});

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './styles/main.css';
import vueDebounce from 'vue-debounce';


const app = createApp(App);
app.use(store);
app.use(router);
app.use(vueDebounce);
app.mount('#app');


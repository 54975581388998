
import { defineComponent } from "vue";
import SideNav from "@/components/template/SideNav.vue";
import Footer from "@/components/template/Footer.vue";
import TopBar from "@/components/template/TopBar.vue";

export default defineComponent({
  name: "Downloads",
  data() {
    return {
      firstname: "",
      menu: true,
      route: "/licenses"
    };
  },
  components: {
    SideNav,
    TopBar,
    Footer,
  },
  mounted() {
    this.firstname = this.$store.state.auth.user.firstName;
  },
  methods: {
    menuOpen() {
      this.menu = !this.menu;
    },
  },
});

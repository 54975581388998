
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocusignEnvelopeModal",
  data() {
    return {
      windowTitle: "One moment please...",
      envId: ""
    };
  },
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    editEnvelopeId: String,
    higherz: Boolean
  },
  methods: {
    closeModal() {
      let query = Object.assign({}, this.$route.query);
      delete query.state;
      this.$router.replace({ query });
      this.$emit('close');
    },
  },
  watch: {
    showing(value) {
      if (value) {
        //return document.querySelector('body').classList.add('overflow-hidden');
      }
      //document.querySelector('body').classList.remove('overflow-hidden');
    },
    editEnvelopeId() {
      console.log("Coming through in the modal");
      console.log(this.editEnvelopeId);
      if (this.editEnvelopeId !== undefined && this.editEnvelopeId !== null) {
        this.envId = this.editEnvelopeId;
      }
    }
  },
});


import { defineComponent, computed } from "vue";
import EnvelopeSearch from "@/components/docusign/EnvelopeSearch.vue";
import EnvelopeRow from "@/components/docusign/EnvelopeRow.vue";
import EnvelopeModal from "@/components/docusign/EnvelopeModal.vue";
import EnvelopeCreate from "@/components/docusign/EnvelopeCreate.vue";
import GrabbaEnvelope from "@/models/docusign/GrabbaEnvelope";
import DocusignService from "@/services/docusign.service";

export default defineComponent({
  name: "DocusignEnvelopeList",
  data() {
    return {
      windowTitle: "Welcome to Envelope list",
      createModal: false,
      completeModal: false,
      firstName: "",
      createModalShowing: false, 
      auditModalShowing: false,
      envelopeList: [] as GrabbaEnvelope[],
      searched: false,
      page: 1, 
      envelopeId: "", 
      envelopesPerPage: 5, 
      enableNext: false, 
      enablePrev: false, 
      searchTerm: ""
    };
  },
  components: {
    EnvelopeSearch,
    EnvelopeRow,
    EnvelopeModal,
    EnvelopeCreate
  },
  props: {
    intent: String,
  },
  async mounted() {
    let paramString = window.location.search.split("?")[1];
    let queryString = new URLSearchParams(paramString);
    var page;
    var search;
    for (let pair of queryString.entries()) {
      if (pair[0] === "p") {
        page = parseInt(pair[1]);
      } else if (pair[0] === "s") {
        search = pair[1];
      }
    }
    if (search !== undefined && search !== null && search.length > 0) {
      this.searchTerm = search;
    }
    if(page && page !== 1) {
      this.page = page;
    }
    if (this.page > 1) {
      this.enablePrev = true;
    }
    this.firstName = this.$store.state.auth.user.firstName;
    // TODO - On mount we can make the requests to the docusign service to grab the envelopes!
    // do something when we change the search field to search for the envelopes instead -> And maybe increase the number of envelopes we show?
    await this.loadPageResults();
    this.searched = true;

    // Open the requested modal if that was the intent.
    switch (this.intent) {
      case "create":
        this.createModalShowing = true;
        break;
      case "complete":
        this.completeModal = true;
        break;
      default:
        break;
    }
  },
  methods: {
    getNext() {
      this.page++;
      this.loadPageResults();
    },
    getPrev() {
      this.page--;
      this.loadPageResults();
    },
    handleEnvelopeData(envelopeData: GrabbaEnvelope[]) {
      if (envelopeData.length > this.envelopesPerPage) {
          // there is at least one more envelope for this user. 
          this.enableNext = true;
          envelopeData = envelopeData.slice(0, -1); // ignore the last one
        } else {
          this.enableNext = false;
        }
        this.envelopeList = envelopeData;
    },
    editEnvelope(envelopeid: string) {
      // now that we have the envelopeId, we need to do something to edit the email
      // 1. get the detailed envelope
      if (envelopeid !== null && envelopeid !== undefined && envelopeid.length > 0) {
        this.createModalShowing = true;
        this.envelopeId = envelopeid; // pass the envelope id in through the prop so that the information can be requested
      }
    }, 
    closeCreateModal() {
      this.createModalShowing = false;
      this.envelopeId = "";
    }, 
    async newSearch(searchTerm: string) {
      this.page = 1;
      if (searchTerm !== undefined && searchTerm !== null && searchTerm !== "") {
        this.searchTerm = searchTerm;
      }
      await this.loadPageResults();
    },
    async cancelSearch() {
      this.page = 1;
      this.searchTerm = "";
      await this.loadPageResults();
    },
    async searchEnvelopes() {
      var response = await DocusignService.searchEnvelopes(this.envelopesPerPage, this.page - 1, this.searchTerm).catch((error) => {
        console.log(error);
      });
      if (response) {
        var tmpEnvList: GrabbaEnvelope[] = response.data as GrabbaEnvelope[]
        this.handleEnvelopeData(tmpEnvList);
      }
    }, 
    async loadPageResults() {
      if (this.page > 1) {
        this.enablePrev = true;
      } else {
        this.enablePrev = false;
      }
      this.searched = false;
      this.envelopeList = [];
      if (this.searchTerm !== undefined && this.searchTerm !== null && this.searchTerm !== "") {
        await this.searchEnvelopes();
      } else {
        this.searched = false;
        this.envelopeList = [];
        var response = await DocusignService.getEnvelopes(this.envelopesPerPage, this.page - 1).catch((error) => {
          console.log(error);
        });
        if (response) {
          var tmpEnvList: GrabbaEnvelope[] = response.data as GrabbaEnvelope[];
          this.handleEnvelopeData(tmpEnvList);
        }
      }
      this.searched = true;
    }
  }
});

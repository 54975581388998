
import { defineComponent } from "vue";

export default defineComponent({
    name: "DocusignEnvelopeSearch",
    data() {
        return {
            searchTerm: "",
            searchArticles: false
        };
    },
    props: {
        intent: String, 
        search: String
    },
    mounted() {
      if (this.search !== undefined && this.search !== null) {
        this.searchTerm = this.search;
      }
    },
    methods: {
      emitSearch() {
        // let the parent element know that we want to search for a particular kind of envelope. 
        console.log("Searching for envelopes");
        this.$emit('searchEnvelopes', this.searchTerm);
      }, 
      cancelSearch() {
        this.$emit('cancelSearch');
      }
    }, 
    watch: {
      search() {
        if (this.search !== undefined && this.search !== null && this.search !== "") {
          this.searchTerm = this.search;
        }
      }
    }
});

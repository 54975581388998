import axios from 'axios';
import authHeader from './auth-header';
import GrabbaEnvelope from "@/models/docusign/GrabbaEnvelope";
import GrabbaSigner from "@/models/docusign/GrabbaSigner";
import EnvelopeRequest from '@/models/docusign/EnvelopeRequest';
import AccessTokenResponse from '@/models/docusign/AccessTokenResponse';
import CheckAccessTokenResponse from '@/models/docusign/CheckAccessTokenResponse';
import EmbeddedSigningRedirect from '@/models/docusign/EmbeddedSigningRedirect';
import User from '@/models/User';

const API_URL = `${process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://localhost:5001'}/api/`;

class DocusignService {
    getAuthUri(redirect: string, st: string) {
        return axios.post<string>(API_URL + `Docusign/authuri/`, { 
            redirect_uri: redirect, 
            state: st 
        }, { 
            headers: authHeader() 
        });
    }

    getAccessToken(authCode: string) {
        return axios.post<boolean>(API_URL + `Docusign/accessToken/`, { 
            auth_code: authCode 
        }, { 
            headers: authHeader() 
        });
    }

    getAccessTokenFromRefresh(refreshToken: string) {
        return axios.post<AccessTokenResponse>(API_URL + `Docusign/refreshToken/`, { 
            refresh_token: refreshToken 
        }, { 
            headers: authHeader() 
        });
    }

    unlink() {
        return axios.get(API_URL + `Docusign/unlink`, {headers: authHeader()});
      }

    getEmbeddedSendingSession(draftEnvelope: EnvelopeRequest, senderRedirect: string, senderId: string) {
        return axios.post<string>(API_URL + `Docusign/embeddedSending/`, {
                docs: draftEnvelope.documents,
                signers: draftEnvelope.signers, 
                email_subject: draftEnvelope.envelope_subject,
                sender_redirect: senderRedirect, 
                sender_id: senderId
            }, {
                headers: authHeader()
            }
        );
    }

    updateEmbeddedSendingSession(envId: string, draftEnvelope: EnvelopeRequest, senderRedirect: string, senderId: string) {
        return axios.post<string>(API_URL + `Docusign/updateSendingSession`, {
            envelope_id: envId,
            docs: draftEnvelope.documents, 
            signers: draftEnvelope.signers, 
            email_subject: draftEnvelope.envelope_subject, 
            sender_redirect: senderRedirect, 
            sender_id: senderId
        }, {
            headers: authHeader()
        })
    }

    resumeEmbeddedSendingSession(envelopeId: string, senderRedirct: string) {
        return axios.post<string>(API_URL + `Docusign/reopenSendingSession`, {
            envelope_id: envelopeId, 
            sender_redirect: senderRedirct
        }, {
            headers: authHeader()
        })
    }

    
    deleteEnvelope(envelopeId: string) {
        return axios.delete<boolean>(API_URL + `Docusign/envelope/` + envelopeId, {
                headers: authHeader()
            }
        );
    }

    getEnvelopes(count: number, page: number) {
        return axios.post<GrabbaEnvelope[]>(API_URL + `Docusign/listEnvelopes/`, {
            amount: count, 
            page_number: page
        }, {
            headers: authHeader()
        })
    }

    searchEnvelopes(count: number, page: number, searchQuery: string) {
        return axios.post<GrabbaEnvelope[]>(API_URL + `Docusign/searchEnvelopes/`, {
            amount: count, 
            page_number: page, 
            search_query: searchQuery
        }, {
            headers: authHeader()
        })
    }

    getRecipientUrls(envelopeId: string) {
        return axios.post<GrabbaSigner[]>(API_URL + `Docusign/signFromEnvelope/`, {
            envelope_id: envelopeId
        }, {
            headers: authHeader()
        })
    }

    updateEnvelope(signerId: string) {
        return axios.post(API_URL + `Docusign/notifyEnvelopeSigned`, {
            signer_id: signerId
        }, {
            headers: authHeader()
        })
    }

    // we need to perform some kind of access token validation. This should probably be on the backend.
    checkAccessToken() {
        return axios.get<CheckAccessTokenResponse>(API_URL + `Docusign/checkToken`, {
            headers: authHeader()
        })
    }

    getSigningUrl(signerId: string) {
        return axios.post<string>(API_URL + `Docusign/getSigningLinkAuth`, {
            signer_id: signerId
        }, {
            headers: authHeader()
        })
    }

    getDetailedEnvelope(envId: string) { 
        return axios.post<GrabbaEnvelope>(API_URL + `Docusign/getDetailedEnvelopeStatus`, {
            envelope_id: envId
        }, {
            headers: authHeader()
        })
    }

    getFullEnvelope(envId: string) {
        return axios.post<GrabbaEnvelope>(API_URL + `Docusign/getFullEnvelope`, {
            envelope_id: envId
        }, {
            headers: authHeader()
        })
    }

    getDetailedEnvelopeBySenderId(senderId: string) {
        return axios.post<GrabbaEnvelope>(API_URL + `Docusign/getAuditorEnvelope`, {
            sender_id: senderId
        }, {
            headers: authHeader()
        })
    }

    sendAuditRequest(senderId: string) {
        return axios.post<string>(API_URL + `Docusign/requestAudit`, {
            sender_id: senderId
        }, {
            headers: authHeader()
        })
    }

    grantAuditAccess(senderId: string, auditorId: string, accessGranted: boolean) {
        return axios.post<boolean>(API_URL + `Docusign/grantAudit`, {
            sender_id: senderId, 
            auditor_id: auditorId, 
            access_granted: accessGranted
        }, {
            headers: authHeader()
        })
    }

    getDocument(envelopeId: string, documentId: string) {
        return axios.post(API_URL + `Docusign/downloadDocument`, {
            envelope_id: envelopeId, 
            document_id: documentId
        }, { 
            headers: authHeader(), 
            responseType: "blob"
        })
    }

    getDocumentURL() {
        return API_URL + `Docusign/downloadDocument`;
    }

    getVerificationImageBySignerGuid(signerGuid: string) {
        return axios.post<string>(API_URL + `Docusign/verificationImage`, {
            signer_guid: signerGuid
        }, {
            headers: authHeader()
        })
    }

    getEmailBySignerGUID(signerGuid: string) {
        console.log(API_URL + `Docusign/signer/` + signerGuid);
        return axios.get<string>(API_URL + `Docusign/signer/` + signerGuid, {
            headers: authHeader()
        });
    }

}

export default new DocusignService();

import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
        closed: false
    };
  }
});

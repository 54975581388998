
import { defineComponent } from "vue";

const features = [
  {
    name: "Documentation",
    description:
      "Access to Grabba Technologies knowledge base to help you build and create your products and services.",
    icon: "bi-code-square",
    route: "/documentation"
  },
  {
    name: "DocuSign Integration",
    description:
      "DocuSign x Grabba integration combines the biometric security of GrabbaID with DocuSign's document signing system.",
    icon: "bi-pen",
    route: "/docusign"
  },
  {
    name: "Support",
    description:
      "How can we help? We’re driven to provide market leading support to all of our clients.",
    icon: "bi-info-square",
    route: "https://www.grabba.com/company/contact-us/"
  },
  {
    name: "Account",
    description:
      "Access critical account information that has been setup automatically by signing up with the GO ID app.",
    icon: "bi-person-circle",
    route: "/account"
  },
];

export default defineComponent({
  data() {
    return {
      features,
      firstname: "",
      lastname: "",
    };
  },
  mounted() {
    this.firstname = this.$store.state.auth.user.firstName;
    this.lastname = this.$store.state.auth.user.lastName;
  },
});

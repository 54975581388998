
import { defineComponent } from "vue";
import DocusignService from "@/services/docusign.service";

export default defineComponent({
    name: "LoadingCard",
    data() {
        return {
            windowTitle: "One moment please...",
            createModal: false,
            completeModal: false
        };
    },
    props: {
        intent: String,
        authCode: String,
        envelopeId: String,
        signerId: String
    },
    mounted() {
        // Make whatever request we need and then redirect
        switch(this.intent) {
            case "send_complete":
                /*
                 * Steps for this process:
                 *      1. get the envelope recipient urls - using sender access token and predefined recipient ids (backend call)
                 *      2. save them in our system so we know which url applies to whom - so we can update their display
                 *      3. send them out via email to the relevant recipients (Optional for MVP)
                 */
                // TODO - Make request to DB to get recipient links
                if(this.envelopeId && this.envelopeId.length > 0) {
                    // this actually sends out the urls to the recipients. We don't care about the response really
                    DocusignService.getRecipientUrls(this.envelopeId ?? '').then((value) => {
                        // Check for a redirect url in the localstorage
                        window.location.replace('/docusign?state=complete');
                    }).catch((e) => {
                        console.log("Error Occurred sending envelopes: ");
                        console.log(e);
                        this.$emit("errorOccurred", e);
                        // should do something with the error
                    });
                } else {
                    window.location.replace('/docusign');
                }
                break;
            case "sign":
                /*
                 * Steps for this process:
                 *      1. get the recipient url for THIS recipient
                 *      2. redirect to sign_complete state
                 */
                if(this.signerId && this.signerId.length > 0) {
                    DocusignService.getSigningUrl(this.signerId ?? '').then((value) => {
                        // some weird docusign behaviour that we're getting spat out right away
                        window.location.replace(value.data);
                    }).catch((e) => {
                        console.log("Error Occurred: ");
                        console.log(e);
                        this.$emit("errorOccurred", e);
                    });
                }
                break;
            case "access":
                // we only get here if BOTH the access token and the refresh token failed
                if (this.authCode !== null && this.authCode !== undefined) {
                    DocusignService.getAccessToken(this.authCode).then((value) => { // we should be checking that the value.data is not false here TODO
                        // Check for a redirect url in the localstorage
                        var tmpRedirect = localStorage.getItem('redirectIntent')?.toString();
                        if (tmpRedirect && tmpRedirect !== "") {
                            localStorage.removeItem('redirectIntent');
                            window.location.replace(tmpRedirect);
                        } else {
                            this.$router.push('/docusign');
                        }
                    }).catch((e) => {
                        console.log("Error Occurred: ");
                        console.log(e);
                        this.$emit("errorOccurred", e);
                    })
                } else {
                    console.log("Error Occurred: Checking access token.")
                }
                break;
            default:
                break;
        }
    }
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-10 max-w-max mx-auto flex items-center cursor-pointer px-5 text-xs font-medium rounded-xl text-white bg-grabba-blue hover:bg-grabba-dark-blue" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "text-white font-montserrat text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}
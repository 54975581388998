
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      firstname: "",
      lastname: "",
      menuval: false
    };
  },
  mounted() {
    this.firstname = this.$store.state.auth.user.firstName;
    this.lastname = this.$store.state.auth.user.lastName;
  },
  methods: {
    clickLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    menuOpen() {
      this.menuval = !this.menuval;
      this.$emit("menuOpen");
    },
  },
});

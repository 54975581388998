
    import { defineComponent } from "vue";
    import DocusignService from "@/services/docusign.service";
    import LoadingCard from "@/components/docusign/LoadingCard.vue";

    export default defineComponent({
        data() {
            return {
                redirectUrl: "https://www.example.com", // these need to be specified as we go in order to make sure we redirect correctly. Not sure if that means they should be specified as 
                                                        // props though
                state: "access",
                docusignLoading: false
            }
        },
        components: {
          LoadingCard
        },
        methods: {
            redirectForLinking() {
                this.docusignLoading = true;
                var authUri = DocusignService.getAuthUri(this.redirectUrl, this.state); // this is actually a Promise? 
                                                                                        // why can't you just be normal and use async/await?
                // some code to redirect to this uri i hope
                authUri.then(
                    (value) => { window.location.href = value.data }, 
                    (error) => { console.log(error) }
                )
            }
        }, 
        props: {
            message: String, // this varies because we might get to this page due to an expired access token or because of no linked account. 
            loading: Boolean// (although if the access token has expired, might we want to automatically redirect them to get the permission?)
        }
    })

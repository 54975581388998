
import GrabbaEnvelope from "@/models/docusign/GrabbaEnvelope";
import GrabbaSigner from "@/models/docusign/GrabbaSigner";
import EnvelopeDetail from "@/components/docusign/EnvelopeDetail.vue";
import EnvelopeModal from "@/components/docusign/EnvelopeModal.vue";
import DocusignService from "@/services/docusign.service";
import { defineComponent } from "vue";
import useVuelidate from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import { reactive } from 'vue';

export default defineComponent({
  name: "DocusignEnvelopeRow",
  setup() {
    const state = reactive({
      delete: ''
    });

    const textCorrect = (text: any): boolean => {
      return (text === "DELETE");
    };

    const rules = {
      delete: {
        required: helpers.withMessage("Please enter a value.", required),
        textCorrect: helpers.withMessage("Text must equal 'DELETE' in uppercase.", textCorrect)
     },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ }
  },
  data() {
    return {
      detailModalShowing: false, 
      detailedEnvelope: undefined as GrabbaEnvelope |  undefined, 
      redirectLoading: false,
      deleteLoading: false,
      confirmDeleteDialog: false,
      isSender: false
    };
  },
  components: {
    EnvelopeDetail,
    EnvelopeModal
  },
  emits: ['editEnvelope'],
  props: {
    envelope: Object as () => GrabbaEnvelope,
  },
  async mounted() {
    if (this.$store.state.auth.user.docusignEmail === this.envelope?.sender.email) {
      this.isSender = true;
    }
  },
  computed: {
    statusClass() {
      if (this.envelope) {
        return {
          "bg-green-500": this.envelope.status === "completed",
          "bg-yellow-500": this.envelope.status === "sent",
          "bg-grabba-red": this.envelope.status === "cancelled" || this.envelope.status === "created",
        };
      }
      return {
        "bg-red-500": true,
      };
    },
    lastModified() {
      const d = new Date();
      if (this.envelope)
      {
        // timezoneoffset returns a value in minutes
        return new Date(this.envelope.last_modified).getTime() - (d.getTimezoneOffset() * 60 * 1000);
      }
      return d.getTime();
    }
  },
  methods: {
    clickDelete() {
      this.confirmDeleteDialog = true;
    },
    async docusignDeleteButton() {
      const isFormCorrect = await this.v$.$validate();
      if(this.envelope && isFormCorrect) {
        this.deleteLoading = true;
        DocusignService.deleteEnvelope(this.envelope?.envelope_id)
          .then((value) => {
            console.log("Envelope deleted");
            this.deleteLoading = false;
            window.location.replace('/docusign');
          })
          .catch((error) => {
            console.log(error);
            this.deleteLoading = false;
          });
      }
    },
    recipientConcat(recipients: GrabbaSigner[]) {
      var concatRet = "";
      if (recipients) {
        for (var i = 0; i < recipients.length; i++) {
          var add = "";
          if ((i + 1) != recipients.length) {
            add = ", ";
          }
          concatRet += recipients[i].signer_email + add;
        }
      }
      return concatRet;
    },
    showDetailedEnvelope() {
      if (this.envelope !== null && this.envelope !== undefined) {
        if (this.envelope.status !== "created") {
          DocusignService.getDetailedEnvelope(this.envelope?.envelope_id).then((value) => {
            this.detailedEnvelope = value.data;
            this.detailModalShowing = true;
          }).catch((error) => {
            this.detailedEnvelope = this.envelope
            this.detailModalShowing = true;
          })
        } else {
          //
          // perform some kind of change here so that we reopen the envelope creator instead. 
          // we want to emit some envelope edit thingo?
          /*
          console.log("Alright, let's emit edit envelope");
          this.$emit("editEnvelope", this.envelope?.envelope_id);
          // maybe do something to stop the loading 
          */
          this.redirectLoading = true;
          DocusignService.resumeEmbeddedSendingSession(this.envelope?.envelope_id, `https://${process.env.VUE_APP_GO_RETURN_DOMAIN}/docusign?state=send_complete`).then((response) => {
            window.location.replace(response.data);
          }).catch((error) => {
            console.log("Error resuming signing session!");
            console.log(error);
            this.redirectLoading = false;
          })
          
        }
      }
    }
  },
});

import axios from 'axios';
import authHeader from './auth-header';
import Article from "../models/Article"

const API_URL = `${process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://localhost:5001'}/api/`;

class ArticleService {
  getArticle(article: string){
    return axios.get<Article>(API_URL + `articles/` + article, { headers: authHeader() });
  }
  searchArticle(searchQuery: string){
    return axios.get<Article[]>(API_URL + `articles?s=` + searchQuery, { headers: authHeader() });
  }
}

export default new ArticleService();

import Article from "@/models/Article";
import { defineComponent } from "vue";
import ArticleService from "../services/article.service";
let article: Article | undefined = undefined;
let error: any;
function htmlDecode(input: string) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent
    ? doc.documentElement.textContent
    : "Content could not be decoded properly.";
}

export default defineComponent({
  props: ["route"],
  data() {
    return {
      article,
      error,
    };
  },
  async mounted() {
    // Get article from service
    try {
      let accRes = await ArticleService.getArticle(this.route);
      if (accRes.data) {
        this.article = accRes.data;
        this.article.content = htmlDecode(accRes.data.content);
      }
    } catch (e) {
      console.log(e);
      this.error = e;
    }
  },
  methods: {},
});


import { defineComponent } from "vue";
import GOID from "@/components/GOID.vue";
import Notification from "@/components/template/Notification.vue";
import FirefoxAndroidNotification from "@/components/template/FirefoxAndroidNotification.vue"

export default defineComponent({
  data() {
    return {
      samsung: false, 
      mobileFirefox: false
    }
  },
  components: {
    GOID,
    Notification, 
    FirefoxAndroidNotification
  },
  methods: {
    onResize() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);  
    }
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let tvh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${tvh}px`);
    window.addEventListener('resize', this.onResize);
    var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    var is_android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if (navigator.userAgent.match(/SamsungBrowser/i)) {
      this.samsung = true;
    } else if (is_firefox && is_android) {
      this.mobileFirefox = true;
    }
  }
});

import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3727c37a")
const _hoisted_1 = {
  id: "modal-content",
  class: "relative bg-white shadow-lg rounded-t-2xl lg:rounded-2xl p-4 lg:p-12 pt-10 lg:min-h-0 lg:max-h-screen w-screen lg:overflow-y-auto lg:w-auto mt-56 lg:mt-0 modal-height"
}
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-gray-400 bg-opacity-30 overflow-y-auto h-full w-full z-20"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showing)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["fixed inset-0 lg:h-auto overflow-auto lg:overflow-hidden my-auto block lg:flex items-center justify-center bg-semi-75 shadow-lg lg:bg-opacity-0 bg-gray-400 bg-opacity-50", {
              'z-50': _ctx.higherz,
              'z-40': !_ctx.higherz
            }])
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("button", {
                  "aria-label": "close",
                  class: "absolute top-0 right-0 text-xl text-gray-500 my-2 mx-4",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["prevent"]))
                }, " x "),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (_ctx.showing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}
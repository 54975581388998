import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:ml-64" }
const _hoisted_2 = { class: "flex flex-col justify-between w-full min-h-screen mt-0" }
const _hoisted_3 = { class: "flex flex-col sm:flex-row" }
const _hoisted_4 = { class: "flex flex-col flex-grow p-4 px-6 pt-5 sm:pt-8 max-w-screen-xl mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_nav = _resolveComponent("side-nav")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_SearchPanel = _resolveComponent("SearchPanel")!
  const _component_Article = _resolveComponent("Article")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_side_nav, {
      menuopen: _ctx.menu,
      active: _ctx.route
    }, null, 8, ["menuopen", "active"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TopBar, {
        onOpenSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuOpen()))
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SearchPanel),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Article, {
            route: _ctx.$route.params.article
          }, null, 8, ["route"]),
          _createVNode(_component_Footer)
        ])
      ])
    ])
  ]))
}
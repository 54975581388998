
import { defineComponent } from "vue";
import QRCode from "qrcode";
import axios from "axios";
import "signalr-no-jquery";
import { hubConnection } from "signalr-no-jquery";
import docusignService from "@/services/docusign.service";

export default defineComponent({
  data() {
    return {
      buttonText: "LOADING...",
      showQR: false,
      showNewAccount: false,
      API_URL: `${process.env.VUE_APP_GO_URL}/api/`,
      sessionID: "",
      error: false,
      errorMessage: undefined as undefined | string,
      buttonDisabled: true,
      readyState: false,
      errorCount: 1,
      waitReset: false, 
      suggestedEmail: "",
      emailAvailable: false, 
      signerId: ""
    };
  },
  created() {
    var mobile =
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
    // If coming from a session we want to try and join the JWE sending session, grab the token and dip
    var sessionLink = window.location.search
      .split("sessionid=")[1];

      // also we want to do something in here to potentially extract the signer email if that's appropriate
    var redirectLink = window.location.search
          .split("login?return=")[0]
          .split("?return=")[1];
    if (redirectLink !== null && redirectLink !== undefined) {
      var signerAndState = redirectLink.split("signerid=")[1];
      if (signerAndState !== null && signerAndState !== undefined) {
        // funky chaining to make sure that it all works properly
        this.emailAvailable = true;
        this.signerId = signerAndState.split("%26state=")[0];
        
        if(mobile) {
          this.buttonDisabled = true;
        }
      }
    }
    
    if (sessionLink !== null && sessionLink !== undefined) {
      // Send button directly into the Verifying state...
      // Join JWE session and collect token
      // Send login to backend api with the collected token
      this.buttonDisabled = true;
      this.showQR = false;
      this.buttonText = "VERIFYING...";
      this.joinExistingSession(sessionLink);
    } else {
      this.resetButton();
    }

    
  },
  methods: {
    async collectSession() {
      var mobile =
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      // Connect and grab the SessionID!!!!
      let config = {
        headers: {
          ClientServiceKey: process.env.VUE_APP_GO_SERVICE_KEY,
          "Content-Type": "application/json",
        },
      };
      if (this.emailAvailable) {
        try {
          this.suggestedEmail = (await docusignService.getEmailBySignerGUID(this.signerId)).data;
        } catch (e) {
          console.log("SIGNER ERROR");
          this.suggestedEmail = "";
        }
      } else {
        this.suggestedEmail = "";
      }

      var licenseData: any = {
        customerID: "IFT09090911",
        customerName: "Grabba Technologies",
        productName: "Grabba Portal",
        base64Logo: "",
        suggestedEmail: this.suggestedEmail,
        productDescription:
          "Grabba Developer Portal provides documentation and support to developers.",
        referrerName: "Grabba",
        validationSuccessReturnUrl: process.env.VUE_APP_GO_RETURN_DOMAIN,
        licenceKey: process.env.VUE_APP_GO_LICENSE_KEY,
      };
      if (mobile) {
        var returnLink = window.location.search
          .split("login?return=")[0]
          .split("?return=")[1];
        if (returnLink && returnLink.length > 0) {
          licenseData["redirectUrl"] =
            process.env.VUE_APP_API_URL + 
            "/login?return=" +
            returnLink +
            "&sessionid="; //Adding the SessionId here to the query so validation check knows to wait for this
        } else {
          licenseData["redirectUrl"] =
            process.env.VUE_APP_API_URL + "/login?sessionid=";
        }
      }
      try {
        await axios
          .post(this.API_URL + "IdentityCheckStart", licenseData, config)
          .then((response) => {
            this.sessionID = response.data.SessionId;
            console.log(this.sessionID);
            if (this.emailAvailable) {
              this.click();
            }
            return "OK";
          });
      } catch (e) {
        return "ERROR";
      }
    },
    startSession() {
      var mobile =
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      // Connect to WS and wait for response from server then send to the auth service store
      const signalrConnection = hubConnection(
        process.env.VUE_APP_SIGR_URL + "/signalr"
      );
      const hub = signalrConnection.createHubProxy("sessionHub");

      hub.on("tokenConsumed", (userData): void => {
        this.buttonDisabled = true;
        this.showQR = false;
        this.buttonText = "VERIFYING...";
        if (userData !== undefined && userData !== null && userData !== "") {
          const parsedUserData = JSON.parse(userData);
          if ("newAccount" in parsedUserData && parsedUserData.newAccount === "true") {
            // do something to display that a new account has been created. There you go 
              this.showNewAccount = true;
          }
        }
      });

      if (mobile) {
        console.log("Mobile Detected, not waiting");
      } else {
        hub.on("broadcastJWE", (data, selfie, profile): void => {
          signalrConnection.stop();
          this.showQR = false;
          this.buttonText = "IDENTITY SECURED";
          this.showNewAccount = false;
          // Handle Login
          this.$store.dispatch("auth/login", [data, selfie, profile]).then(
            () => {
              try {
                var returnLink = window.location.search
                  .split("login?return=")[0]
                  .split("?return=")[1];
                if (returnLink && returnLink.length > 0) {
                  window.location.href = decodeURIComponent(returnLink);
                } else {
                  throw new Error();
                }
              } catch (e) {
                console.log("Could not find a return query so returning home.");
                this.$router.push("/");
              }
            },
            (error) => {
              console.log(error.response.data);
              this.errorMessage = "Error occured in the account creation process.";
              this.error = true;
              return;
            }
          );
        });
        hub.on("broadcastFailure", (failureMessage): void => {
          signalrConnection.stop();
          this.showQR = false;
          this.errorMessage = failureMessage; // Set the error message here
          this.error = true;
          this.buttonText = "VERIFICATION FAILED";
          this.waitReset = true;
        });
      }

      signalrConnection.start().done(() => {
        // Do some initialization once you know the connection has been started
        // For instance, call a method on the server
        hub.invoke("JoinSession", this.sessionID);
        // Reset button if nothing has been received for 5 minutes
        setTimeout(() => {
          signalrConnection.stop();
          console.log(">>>> Timeout reset login button.");
          this.resetButton();
        }, 3000000);
      });
    },
    async joinExistingSession(sessionID: string) {
      // Connect to WS and wait for response from server then send to the auth service store
      const signalrConnection = hubConnection(
        process.env.VUE_APP_SIGR_URL + "/signalr"
      );
      const hub = signalrConnection.createHubProxy("sessionHub");

      hub.on("broadcastJWE", (data, selfie, profile): void => {
        signalrConnection.stop();
        this.showQR = false;
        this.buttonText = "IDENTITY SECURED";
        // Handle Login
        this.$store.dispatch("auth/login", [data, selfie, profile]).then(
          () => {
            try {
              var returnLink = window.location.search
                .split("login?return=")[0]
                .split("?return=")[1]
                .split("sessionid=")[0];
              if (returnLink && returnLink.length > 0) {
                window.location.href = decodeURIComponent(returnLink);
              } else {
                throw new Error();
              }
            } catch (e) {
              console.log("Could not find a return query so returning home.");
              this.$router.push("/");
            }
          },
          (error) => {
            console.log(error.response.data);
              if (error.response.data) {
                this.errorMessage = error.response.data;
              }
            this.error = true;
            return;
          }
        );
      });

      signalrConnection.start().done(() => {
        // Do some initialization once you know the connection has been started
        // For instance, call a method on the server
        hub.invoke("MobileSessionJoin", sessionID);
        // Reset button if nothing has been received for 5 minutes
        setTimeout(() => {
          signalrConnection.stop();
          console.log(">>>> Timeout reset login button.");
          this.resetButton();
        }, 30000);
      });
    },
    async resetButton() {
      this.buttonDisabled = true;
      this.buttonText = "LOADING...";
      this.showQR = false;
      this.sessionID = "";
      this.error = false;
      this.errorMessage = undefined;
      this.waitReset = false;

      let sessionResult = await this.collectSession();
      if (sessionResult == "ERROR") {
        this.buttonDisabled = true;
        this.error = true;
        this.buttonText = "RETRYING...";
        this.errorCount++;
        await setTimeout(() => {
          this.error = false;
          this.resetButton();
        }, 5000 * this.errorCount);
      } else {
        this.buttonDisabled = false;
        this.buttonText = "VERIFY WITH GRABBA";
      }
    },
    click() {
      // This is overly complex to deal with inconsistentcies in how browsers handle links being opened.
      let newWindow: any;
      if (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.buttonDisabled = true;
        this.buttonText = "OPENING APP...";
        window.location.href =
          process.env.VUE_APP_GO_URL + "?s=" + this.sessionID;
        this.mobileClick();
      } else {
        this.nextClick(undefined);
      }
    },
    async mobileClick() {
      if (this.showQR == false) {
        await this.startSession();
      } else {
        this.showQR = false;
        this.buttonText = "GRABBA ID";
      }
    },
    async nextClick(newWindow: any) {
      if (this.showQR == false) {
        // Don't do it again if you've already done it you silly salmon!
        this.buttonText = "CONNECTING...";

        // If on mobile don't create QR
        if (
          /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          if (newWindow) {
            newWindow.location.href = `${
              process.env.VUE_APP_API_URL
            }/r/${encodeURIComponent(
              process.env.VUE_APP_GO_URL + "?s=" + this.sessionID
            )}`;
          }
          this.buttonText = "OPENING GRABBA ID...";
        } else {
          // Generate the QR code with the GrabbaSession ID
          var canvas = document.getElementById("qrcode");
          this.buttonText = "CREATING QR...";
          await QRCode.toCanvas(
            canvas,
            `${process.env.VUE_APP_GO_URL}?s=${this.sessionID}`,
            {
              margin: 0,
              scale: 4,
              color: {
                dark: "#000a2c",
              },
            },
            (err) => {
              if (err != null || err != undefined) {
                console.log(err);
              }
            }
          );
          this.showQR = true;
          this.buttonText = "SCAN QR...";
        }
        await this.startSession();
      } else {
        this.showQR = false;
        this.buttonText = "GRABBA ID";
      }
    },
  },
});


    import { defineComponent } from "vue";
    import GrabbaDocument from "@/models/docusign/GrabbaDocument";
    import DocusignService from "@/services/docusign.service";

    export default defineComponent({
        name: "DocusignEnvelopeRow",
        data() {
            return {
                downloadLoading: false
            };
        }, 
        props: {
            grabbaDocument: Object as () => GrabbaDocument,
            envelopeId: String
        },
        methods: {
            downloadDocument() {
                if (this.grabbaDocument !== null && this.grabbaDocument !== undefined && this.envelopeId !== undefined && this.envelopeId !== null) {
                    this.downloadLoading = true;
                    DocusignService.getDocument(this.envelopeId, this.grabbaDocument.document_id.toString()).then((response) => {
                        var fileUrl = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileUrl;
                        fileLink.setAttribute('download', this.grabbaDocument !== undefined ? this.grabbaDocument.document_name : "file.pdf");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        this.downloadLoading = false;
                    }).catch((error) => {
                        console.log("We got this error: " + error)
                    })
                }
            }
        }
    });

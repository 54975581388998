import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://localhost:5001'}/api/`;

class AuthService {
  login(data: string, selfie: string, profile: string) {
    // Post recieved JWE from GrabbaID to DP backend to start DP session.
    return axios
      .post(API_URL + 'login', {
        GOToken: data,
        Selfie: selfie,
        ProfilePhoto: profile
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  verifyemail() {
    return axios
      .get(API_URL + 'verifyemail', { headers: authHeader() });
  }

  checkemail(uuid: string) {
    return axios
      .post(API_URL + 'checkemail', {
        email_verification_uuid: uuid
      }, { headers: authHeader() });
  }
}

export default new AuthService();